import React from "react";
import Axios from 'axios';
import {DateTime} from 'luxon';
import { Button, Card, Container, Form, InputGroup, Table } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft, faArrowLeftRotate, faArrowRight, faClock, faClose, faDatabase, faFileDownload, faFileExcel, faLongArrowAltLeft, faRefresh } from "@fortawesome/free-solid-svg-icons";
import SendForm from "./SendForm";
import LoginForm from "./LoginForm";
import qs from 'qs';
import ExportExcel from "./ExportExcel";



export default class App extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            loginForm: true
        }
    }

    async componentDidMount(){
        this.setState({
            data: await this.refresh()
        })
    }
    sendData(){
        this.setState({
            sendForm: true
        })
    }
    exportExcel(){
        let from = this.dataFrom?this.dataFrom.value:null;
        let to = this.dataTo?this.dataTo.value:null;
        const url = window.location.origin + "/";
        if(from == null)
            from = "";
        else
            from = "from=" + from + "&";
        if(to == null)
            to = "";
        else
            to = "to=" + to;
        window.open(url + "export.php?" + from + to +"&utente="+this.state.utente+"&pass="+this.state.pass);
    }
    exportExcelCicli(){
        this.setState({
            exportExcelForm: true
        })
    }
    async refresh(from, to){
        const url = window.location.origin + "/";
        const res = await Axios.post(url + "list.php", qs.stringify({
            from: from,
            to: to,
            utente: this.state.utente,
            pass: this.state.pass
        }));
        if(res.data.stato == "ok")
            return res.data.data;
        return [];
    }


    prova(utente, pass){
        this.setState({
            loginForm: false,
            utente: utente,
            pass: pass
        })
    }

    render(){
        
        if(this.state && this.state.loginForm){
            return (<LoginForm parentCallback = {(utente, pass)=>this.prova(utente, pass)}></LoginForm>);
        }

        if(this.state && this.state.exportExcelForm){
            return <ExportExcel utente={this.state.utente} password={this.state.pass} indietro={() => this.setState({
                exportExcelForm: false
            })}/>;
        }
        if(this.state && this.state.sendForm){
            return <SendForm utente={this.state.utente} pass={this.state.pass} indietro={() => this.setState({
                sendForm: false
            })}></SendForm>
        }

        let rows = this.state && this.state.data?this.state.data.map(d => {
            return {
                timestamp: DateTime.fromFormat(d.timestamp, "yyyy-MM-dd HH:mm:ss"),
                totLavoro: (d.TotalizzatoreOreLavoro??"").toString().padStart(1, "0") + ":" + (d.TotalizzatoreMinutiLavoro??"").toString().padStart(2, "0") + ":" + (d.TotalizzatoreSecondiLavoro??"").toString().padStart(2, "0"),
                totTagliando: (d.TotalizzatoreOreTagliando??"").toString().padStart(1, "0") + ":" + (d.TotalizzatoreMinutiTagliando??"").toString().padStart(2, "0") + ":" + (d.TotalizzatoreSecondiTagliando??"").toString().padStart(2, "0"),
                parzialePacco: d.ParzialeCicliPacco,
                totalePacco: d.TotalizzatoreCicliPacco,
                parzialePrepacco: d.ParzialeCicliPrepacco,
                totalePrepacco: d.TotalizzatoreCicliPrepacco,
                parzialeCesoia: d.ParzialeCicliCesoia,
                totaleCesoia: d.TotalizzatoreCicliCesoia,
            }
        }):[];
        return <Container>
                <div style={{display:"flex", flexDirection:"row-reverse", alignItems: "center"}}>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Aggiorna</Tooltip>}>
                        <button className="refreshBtn" onClick={async () => this.setState({
                                        data: await this.refresh(this.dataFrom?this.dataFrom.value:null, this.dataTo?this.dataTo.value:null)
                                    })} ><FontAwesomeIcon icon={faRefresh} /></button>
                    </OverlayTrigger>
                    <InputGroup style={{width:"auto"}}>
                        <InputGroup.Text>Da</InputGroup.Text>
                        <input ref={ref => this.dataFrom = ref} type="date" defaultValue={DateTime.now().minus({month: 1}).toFormat("yyyy-MM-dd")} />
                        <InputGroup.Text>A</InputGroup.Text>
                        <input ref={ref => this.dataTo = ref} type="date" defaultValue={DateTime.now().toFormat("yyyy-MM-dd")} style={{borderBottomRightRadius: "6px", borderTopRightRadius: "6px"}}/>
                    </InputGroup>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Invia dati macchina</Tooltip>}>
                        <button className="refreshBtn" onClick={() => this.sendData()} ><FontAwesomeIcon icon={faDatabase} /></button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Esporta storico dati</Tooltip>}>
                        <button className="refreshBtn" onClick={() => this.exportExcel()} ><FontAwesomeIcon icon={faFileDownload} /></button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={<Tooltip>Esporta excel cicli</Tooltip>}>
                        <button className="refreshBtn" onClick={() => this.exportExcelCicli()} ><FontAwesomeIcon icon={faFileExcel} /></button>
                    </OverlayTrigger>
                </div>
                <div className="tableContainer">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th><FontAwesomeIcon icon={faClock} /></th>
                                <th>Tot. lavoro</th>
                                <th>Tot. tagliando</th>
                                <th>Parziale cicli pacco</th>
                                <th>Tot. cicli pacco</th>
                                <th>Parziale cicli prepacco</th>
                                <th>Tot. cicli prepacco</th>
                                <th>Parziale cicli cesoia</th>
                                <th>Tot. cicli cesoia</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((r, ik) => <tr key={ik}>
                                <td className="timestamp">{r.timestamp.toFormat("dd-MM-yyyy")} {r.timestamp.toFormat("HH:mm:ss")}</td>
                                <td className="ore">{r.totLavoro.split(":").map((p, i) => <span key={i} className={i === 0?"ora":(i === 1?"minuto":"secondo")}>{p}</span>)}</td>
                                <td className="ore">{r.totTagliando.split(":").map((p, i) => <span key={i} className={i === 0?"ora":(i === 1?"minuto":"secondo")}>{p}</span>)}</td>
                                <td className="numero">{r.parzialePacco??"-"}</td>
                                <td className="numero">{r.totalePacco??"-"}</td>
                                <td className="numero">{r.parzialePrepacco??"-"}</td>
                                <td className="numero">{r.totalePrepacco??"-"}</td>
                                <td className="numero">{r.parzialeCesoia??"-"}</td>
                                <td className="numero">{r.totaleCesoia??"-"}</td>
                            </tr>)}
                        </tbody>
                    </Table>
                </div>
        </Container>;
        
    }
}