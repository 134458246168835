import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React from "react";
import { Button, ButtonGroup, Container, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Swal from 'sweetalert2';
import qs from 'qs';

export default class SendForm extends React.Component{
    async send(key){
        let res = await Swal.fire({
            title: 'Inviare i dati?',
            icon: 'warning',
            confirmButtonText: 'Si',
            showCancelButton: true,
            cancelButtonText: "Annulla"
        })
        if(res.isConfirmed){
            const url = window.location.origin + "/";
            let data = {};
            data[key] = "si";
            try{
                const res = await Axios.post(url + "queueSend.php", qs.stringify({
                    data: data,
                    utente: this.props.utente,
                    pass: this.props.pass
                }));
                if(res.data.stato != "ok")
                    throw new Error("e");
                
                Swal.fire({
                    title: 'Dati inviati',
                    icon: 'success',
                })
            }
            catch(e){
                Swal.fire({
                    title: 'Errore invio dati',
                    icon: 'error',
                })
            }
        }
    }
    render(){
        return <Container>
            <div style={{display:"flex"}}>
                <OverlayTrigger placement="top" overlay={<Tooltip>Torna all'elenco</Tooltip>}>
                    <button className="refreshBtn" onClick={() => this.props.indietro()} ><FontAwesomeIcon icon={faArrowLeft} /></button>
                </OverlayTrigger>
            </div>
            <div  style={{gap: "5px", display: "flex", flexDirection: "column"}}>
                <Row>
                    <ButtonGroup>
                        <Button variant={"danger"} onClick={() => this.send("PbResetTotalizzatoreOreLavoro")}>Reset ore lavoro</Button>
                    </ButtonGroup>
                </Row>
                <Row>
                    <ButtonGroup>
                        <Button variant={"light"} onClick={() => this.send("PbResetParzialeCicliPacco")}>Reset parziale pacco</Button>
                        <Button variant={"light"} onClick={() => this.send("PbResetParzialeCicliPrepacco")}>Reset parziale prepacco</Button>
                        <Button variant={"light"} onClick={() => this.send("PbResetParzialeCicliCesoia")}>Reset parziale cesoia</Button>
                    </ButtonGroup>
                </Row>
                <Row>
                    <ButtonGroup>
                        <Button variant={"light"} onClick={() => this.send("PbResetTotalizzatoreCicliPacco")}>Reset totalizzatore pacco</Button>
                        <Button variant={"light"} onClick={() => this.send("PbResetTotalizzatoreCicliPrepacco")}>Reset totalizzatore prepacco</Button>
                        <Button variant={"light"} onClick={() => this.send("PbResetTotalizzatoreCicliCesoia")}>Reset totalizzatore cesoia</Button>
                    </ButtonGroup>
                </Row>
            </div>
        </Container>
    }
}