import React from "react";
import { Button, ButtonGroup, Col, Container, Form, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import qs from 'qs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";

export default class ExportExcel extends React.Component{
    export(){
        let from = this.dataFrom?this.dataFrom.value:null;
        let to = this.dataTo?this.dataTo.value:null;
        let mat  =this.materiale?this.materiale.value:null;
        let previsti = this.previsti?this.previsti.value:null;
        if(!from || !to || !mat || !previsti)
            return;
        const url = window.location.origin + "/";
        window.open(url + "export2.php?" + qs.stringify({
            from: from,
            to: to,
            materiale: mat,
            previsti: previsti,
            utente: this.props.utente,
            pass: this.props.password
        }));
    }
    render(){
        return <Container>
            <div style={{display:"flex"}}>
                <OverlayTrigger placement="top" overlay={<Tooltip>Torna all'elenco</Tooltip>}>
                    <button className="refreshBtn" onClick={() => this.props.indietro()} ><FontAwesomeIcon icon={faArrowLeft} /></button>
                </OverlayTrigger>
            </div>
            <div style={{gap: "5px", display: "flex", flexDirection: "column"}}>
                <Row>
                    <InputGroup>
                        <InputGroup.Text variant={"light"}>Materiale</InputGroup.Text>
                        <Form.Control ref={ref => this.materiale = ref} type="text" variant={"light"} />
                        <InputGroup.Text variant={"light"}>Cicli previsti</InputGroup.Text>
                        <Form.Control ref={ref => this.previsti = ref} type="number" variant={"light"} />
                    </InputGroup>
                </Row>
                <Row>
                    <Col md={6}>
                        <InputGroup>
                            <InputGroup.Text variant={"light"}>Da</InputGroup.Text>
                            <Form.Control ref={ref => this.dataFrom = ref} type="date" variant={"light"} />
                            <InputGroup.Text variant={"light"}>a</InputGroup.Text>
                            <Form.Control ref={ref => this.dataTo = ref} type="date" variant={"light"} />
                        </InputGroup>
                    </Col>
                    <Col md={6}>
                        <Button type="button" variant={"success"} onClick={() => this.export()}>
                            <FontAwesomeIcon icon={faDownload} />
                        </Button>
                    </Col>
                </Row>
            </div>
        </Container>
    }
}