import React from "react";
import { Button, Card, Container, Form, InputGroup, Row, Table } from 'react-bootstrap'
import Swal from 'sweetalert2';
import qs from 'qs';
import Axios from "axios";

export default class LoginForm extends React.Component{

    utente;
    password;

    async submited(e){
        e.preventDefault();
        if(this.utente.value && this.password.value){
            const url = window.location.origin + "/";
            try{
                const res = await Axios.post(url + "login.php", qs.stringify({
                    utente: this.utente.value,
                    pass: this.password.value
                }));

                if(res.data.stato != "ok")
                    throw new Error("Utente o password non validi");

                this.props.parentCallback(this.utente.value, this.password.value);
            }
            catch(e){
                Swal.fire({
                    title: e.message,
                    icon: 'error',
                })
            }
        } else{
            Swal.fire({
                title: 'Utente o password non inseriti',
                icon: 'error',
            })
        }
        
        
    }


    render(){
        return <Container>
            <div style={{display:"flex", flexDirection:"columns", alignItems: "center", justifyContent: "center"}}>
                <Form onSubmit={(e)=>this.submited(e)}>
                    <Row className="mb-4">
                        <Form.Label className="loginLabel">Utente</Form.Label>
                        <Form.Control ref={ref => this.utente = ref} type="text" placeholder="Inserisci Utente" />
                    </Row>
                    <Row className="mb-4">
                        <Form.Label className="loginLabel">Password</Form.Label>
                        <Form.Control ref={ref => this.password = ref} type="password" placeholder="Inserisci Password" />
                    </Row>
                    <Row className="mb-4"><Button type="submit">Accedi</Button></Row>
                </Form>
            </div>
        </Container>;
    }
}
